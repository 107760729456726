import { ApplicationConfig, DEFAULT_CURRENCY_CODE, ErrorHandler, provideZoneChangeDetection } from '@angular/core';
import { PreloadAllModules, provideRouter, withComponentInputBinding, withPreloading } from '@angular/router';

import { APP_ROUTES } from './app.routes';
import { provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { initializerApp } from '@core/initializer/initializer-app';

import { CustomHttpInterceptor } from '@core/interceptors/custom.http.interceptor';
import { GlobalErrorHandler } from '@core/global-error-handler';
import { provideStore } from '@ngrx/store';
import { ROOT_REDUCERS } from './app.state';
import { provideEffects } from '@ngrx/effects';
import { ContratosEffects } from './contratos/contratos-v2/state/contratos.effects';
import { StateGlobalEffects } from '@core/stateGlobal';
import { ExpedienteEffects } from './expediente/state/state.expediente.effects';
import { UserEffects } from './clientes/state/state.usuario.effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideLottieOptions } from 'ngx-lottie';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideClientHydration } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MsalConfigAuth } from '@core/graph/graph.provider.config';
import { provideRouterStore } from '@ngrx/router-store';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DATE_LOCALE } from '@angular/material/core';

registerLocaleData(localeEs, 'es-MX');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(APP_ROUTES, withPreloading(PreloadAllModules), withComponentInputBinding()),
    provideNoopAnimations(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    //{ provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    ...MsalConfigAuth,
    initializerApp,
    provideStore(ROOT_REDUCERS),
    provideEffects([ContratosEffects, StateGlobalEffects, ExpedienteEffects, UserEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideEnvironmentNgxMask(),
    provideClientHydration(),
    provideRouterStore(),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
};
