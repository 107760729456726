import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { delay, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { RingCentralComponent } from '../../ring-central/ring-central.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalApiActions, GlobalSelectors } from '@core/stateGlobal';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

import { SidebarComponent } from '../components/sidenav/sidenav.component';

import { ProfileType } from '@core/models/utils.model';
import { SubSink } from 'subsink';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoaderAndUtilsService } from '@core/services/loaderAndUitils.service';
import { avatarName } from '@shared/utils/utils';
import { ShowForRolesDirective } from '@core/directives/show-for-roles.directive';
import { RefreshAlertComponent } from '../components/refresh-alert/refresh-alert.component';
import { OverlayModule } from '@angular/cdk/overlay';

//import { NotificacionesWsComponent } from '../../notificaciones-ws/notificaciones-ws.component';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    RingCentralComponent,
    RouterModule,
    MatSidenavModule,
    SidebarComponent,
    MatToolbarModule,
    MatIconModule,
    ShowForRolesDirective,
    RefreshAlertComponent,
    OverlayModule,
  ],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly subs = new SubSink();

  loginDisplay = false;
  isOpenBody = false;

  private readonly authService = inject(MsalService);
  private readonly msalBroadcastService = inject(MsalBroadcastService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly _loaderAndUtilsService = inject(LoaderAndUtilsService);
  private readonly changeDetector = inject(ChangeDetectorRef);
  isEnvProd = environment.production;
  displayRingCentral = false;

  profile!: ProfileType;

  isContainer = false;

  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  ngOnInit(): void {
    const userSession$ = this.store.select(GlobalSelectors.selectUserSession);

    this.subs.add(
      this._loaderAndUtilsService
        .isMobileObserver()
        .pipe(delay(1), untilDestroyed(this))
        .subscribe((isMobile: any) => {
          if (isMobile.matches) {
            this.sidenav.mode = 'over';
            this.sidenav.close();
          } else {
            this.sidenav.mode = 'side';
            this.sidenav.open();
          }
        }),

      this.msalBroadcastService.msalSubject$
        .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
        }),

      this.msalBroadcastService.inProgress$
        .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
        .subscribe(() => {
          this.setLoginDisplay();
        }),

      userSession$.subscribe(({ groupsGraph, profileGraph }) => {
        if (!groupsGraph && !profileGraph) return;

        this.displayRingCentral = groupsGraph.includes('RingCentral-CC');

        this.profile = { ...profileGraph, letterAvatar: avatarName(profileGraph.displayName || 'N A') } as ProfileType;
      })
    );
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  logout(popup?: boolean) {
    sessionStorage.clear();
    localStorage.clear();
    if (popup)
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    else this.authService.logoutRedirect();
  }

  ngAfterViewInit() {
    this.subs.add(
      this.router.events
        .pipe(
          untilDestroyed(this),
          filter((e) => e instanceof NavigationEnd)
        )
        .subscribe(() => {
          if (this.sidenav.mode === 'over') this.sidenav.close();
        }),
      this._loaderAndUtilsService.isContainer$.subscribe((isContainer) => {
        this.isContainer = isContainer;
      })
    );

    this.changeDetector.detectChanges();
  }

  themeEvent() {
    localStorage['theme'] = 'dark';
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
